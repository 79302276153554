<template>
  <div>
    <SingleModelLayout
      :model="order"
      :can-edit="true"
      :can-delete="false"
    >
      <template #title>
        <TitleSummaryCard
          :can-edit="true"
          :edit.sync="edit"
          class="mb-4"
          @edit="startEdit"
          @save="saveOrder"
          @discard="discardEdit"
        >
          <template #title>
            Order for {{ order.customer_name }}
          </template>
          <template #subtitle class="opacity-50">
            {{ order.id }}
          </template>
        </TitleSummaryCard>
      </template>
      <template #left>
        <div class="mb-8">
          <h3 class="flex items-center text-lg mb-2">
            <font-awesome-icon :icon="['fas', 'user']" fixed-width class="mr-2" />
            Customer
          </h3>
          <vue-data
            v-model="order.customer_name"
            :value="order.customer_name"
            :edit="edit"
            class="mb-2"
            label="Name"
          />
          <vue-data
            v-model="order.customer_email"
            :value="order.customer_email"
            :edit="edit"
            class="mb-2"
            label="Email"
          />
          <vue-data
            v-model="order.customer_phone"
            :value="order.customer_phone"
            :edit="edit"
            class="mb-2"
            label="Phone Number"
          />
          <vue-data
            v-model="order.customer_mobile"
            :value="order.customer_mobile"
            :edit="edit"
            class="mb-2"
            label="Mobile Number"
          />
        </div>
        <div class="mb-8">
          <h3 class="flex items-center text-lg mb-2">
            <font-awesome-icon :icon="['fas', 'box-usd']" fixed-width class="mr-2" />
            Billing
          </h3>
          <vue-data
            v-model="order.billing_address"
            :value="order.billing_address"
            :edit="edit"
            type="textarea"
            class="mb-2"
            label="Address"
            rows="4"
          />
          <vue-data
            v-model="order.billing_postcode"
            :value="order.billing_postcode"
            :edit="edit"
            class="mb-2"
            label="Postcode"
          />
        </div>
        <div class="mb-8">
          <h3 class="flex items-center text-lg mb-2">
            <font-awesome-icon :icon="['fas', 'truck-loading']" fixed-width class="mr-2"
            />
            Shipping
          </h3>
          <vue-data
            v-model="order.use_billing_for_shipping"
            :value="order.use_billing_for_shipping"
            :edit="edit"
            type="checkbox"
            class="mb-2"
            label="Use Billing Address for Shipping?"
          />
          <template v-if="!order.use_billing_for_shipping">
            <vue-data
              v-model="order.shipping_address"
              :value="order.shipping_address"
              :edit="edit"
              type="textarea"
              class="mb-2"
              label="Address"
              placeholder="Same as Billing Address"
              rows="4"
            />
            <vue-data
              v-model="order.shipping_postcode"
              :value="order.shipping_postcode"
              :edit="edit"
              class="mb-2"
              placeholder="Same as Billing Postcode"
              label="Postcode"
            />
          </template>
        </div>
      </template>
      <template #main>
        <template
          v-if="order.products && order.products.length > 0"
          class="flex flex-col"
        >
          <section class="mb-8">
            <div class="flex items-center justify-between mb-2">
              <h3 class="text-2xl">
                Products
              </h3>
            </div>
            <div v-for="(product, index) in order.products" :key="index" class="flex flex-wrap bg-white rounded-sm mb-4">
              <figure
                v-if="product.images && product.images.featured"
                class="w-full lg:w-1/4 h-48"
              >
                <img
                  :src="product.images.featured.small"
                  :title="product.name"
                  :alt="product.name"
                  class="w-full h-full object-contain"
                />
              </figure>
              <div class="w-full flex-grow">
                <vue-card class="relative h-full border-l-0 rounded-l-none space-y-4">
                  <div class="flex justify-between w-full">
                    <div>
                      <h4 class="text-lg font-bold">
                        <span v-if="product && product.pivot && product.pivot.order_data && product.pivot.order_data.custom_name && product.pivot.order_data.custom_name !== 'Custom Build'">
                          “{{ product.pivot.order_data.custom_name }}” -
                        </span>
                        <span>{{ product.name }}</span>
                      </h4>
                      <span class="text-sm">
                        SKU: {{ product.sku }}
                      </span>
                    </div>
                    <div class="flex flex-col space-y-1">
                      <div v-if="product.pivot.qty > 1">
                        QTY: {{ product.pivot.qty ? product.pivot.qty : '1' }}
                      </div>
                      <div v-if="product.pivot.subtotal">
                        PPU: £{{product.pivot.subtotal.toFixed(2)}}
                      </div>
                      <div v-if="product.pivot.total">
                        Total: £{{product.pivot.total.toFixed(2)}}
                      </div>
                    </div>
                  </div>
                  <div v-for="section in productPartSections" :key="section.data">
                    <p class="font-bold mb-1">
                      {{ section.label }}:
                    </p>
                    <ul v-if="product && product.pivot && product.pivot.order_data && product.pivot.order_data[section.data] && product.pivot.order_data[section.data].length > 0" class="flex flex-col w-full space-y-1">
                      <li v-for="part in product.pivot.order_data[section.data]" :key="part.id" class="flex justify-between items-center bg-gray-100 w-full px-2 py-1">
                        <div class="grid auto-rows-fr gap-2 w-full" style="grid-template-columns: 3fr 2fr 1fr auto;">
                          <span class="flex items-center">{{ part.name }}</span>
                          <span class="flex items-center">{{ part.sku }}</span>
                          <span class="flex items-center justify-end">{{ part.price ? `£${part.price.toFixed(2)}` : 'No Price' }}</span>
                          <div class="flex items-center justify-end">
                            <vue-link
                              :to="{
                                name: 'parts.single',
                                params: {
                                  model: part.id
                                }
                              }"
                              :icon="['fas', 'eye']"
                              target="_blank"
                              text
                              sm
                            />
                          </div>
                        </div>
                      </li>
                      <li v-if="product.pivot.order_data[`${section.data}_subtotal`]" class="flex items-center justify-end space-x-1 px-2 py-1">
                        {{ section.label }} Subtotal: £{{ product.pivot.order_data[`${section.data}_subtotal`].toFixed(2) }}
                      </li>
                    </ul>
                    <div v-else class="text-gray-700 py-1">
                      This product has no {{ section.label }} included.
                    </div>
                  </div>
                </vue-card>
              </div>
            </div>
          </section>
        </template>
      </template>
      <template #right>
        <div class="mb-8">
          <template v-if="order.payment_method === 2">
            <h6 class="flex font-bold items-center text-md mb-2">
              Paypal Ref Number
            </h6>
            <div class="mb-5">
              {{ order.payment_info.id }}
            </div>
          </template>
          <h3 class="flex items-center text-lg mb-2">
            <font-awesome-icon :icon="['fas', 'truck-loading']" fixed-width class="mr-2"
            />
            Order Status
          </h3>
          <div class="mb-2">
            <label class="text-sm mb-1">
              Billing Status
            </label>
            <div class="flex flex-col space-y-2">
              <data
                v-if="!edit"
                :value="order.billing_status"
                class="flex-grow bg-gray-900 text-white rounded px-3 py-2"
              >
                {{ order.billing_status_label }}
              </data>
              <div v-if="edit && selectableBillingStatuses.length > 0" class="flex">
                <vue-select
                  v-model="form.billing_status"
                  :options="selectableBillingStatuses"
                  :disabled="loading"
                  sm
                  aria-label="Choose a new status"
                  class="flex-grow"
                />
                <vue-button
                  :icon="['fas', 'sign-in']"
                  :disabled="!selectableBillingStatuses.length > 0 || !form.billing_status || form.billing_status === order.billing_status || loading"
                  primary
                  flip
                  sm
                  aria-label="Update Billing Status"
                  class="ml-1"
                  @click="changeStatus('billing', form.billing_status)"
                >
                  Update
                </vue-button>
              </div>
            </div>
          </div>
          <div class="mb-2">
            <label class="text-sm mb-1">
              Fulfillment Status
            </label>
            <div class="flex flex-col space-y-2">
              <data
                v-if="!edit"
                :value="order.fulfillment_status"
                class="flex-grow bg-gray-900 text-white rounded px-3 py-2"
              >
                {{ order.fulfillment_status_label }}
              </data>
              <div v-if="edit && selectableFulfillmentStatuses.length > 0" class="flex">
                <vue-select
                  v-model="form.fulfillment_status"
                  :options="selectableFulfillmentStatuses"
                  :disabled="loading"
                  aria-label="Choose a new status"
                  class="flex-grow"
                />
                <vue-button
                  :icon="['fas', 'sign-in']"
                  :disabled="!selectableFulfillmentStatuses.length > 0 || !form.fulfillment_status || form.fulfillment_status === order.fulfillment_status || loading"
                  primary
                  flip
                  sm
                  aria-label="Update Fulfillment Status"
                  class="ml-1"
                  @click="changeStatus('fulfillment', form.fulfillment_status)"
                >
                  Update
                </vue-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </SingleModelLayout>
  </div>
</template>
<script type="text/javascript">
import { cloneDeep } from 'lodash'

import SingleModelLayout from '@/components/layouts/SingleModelLayout'
import TitleSummaryCard from '@/components/TitleSummaryCard'

export default {
  name: 'OrdersSingle',

  components: {
    SingleModelLayout,
    TitleSummaryCard
  },

  data () {
    return {
      loading: false,
      edit: false,
      order: {},
      orderOld: false,
      products: {},
      error: {},
      form: {
        billing_status: false,
        fulfillment_status: false
      },
      productDropdown: false,
      productPartSections: [
        {
          label: 'Components',
          data: 'parts'
        },
        {
          label: 'Peripherals',
          data: 'bundle'
        }
      ]
    }
  },

  computed: {
    selectableBillingStatuses () {
      return this.order ? [
        this.order.billing_status,
        ...this.order.available_billing_statuses
      ] : []
    },

    selectableFulfillmentStatuses () {
      return this.order ? [
        this.order.fulfillment_status,
        ...this.order.available_fulfillment_statuses
      ] : []
    }
  },

  mounted () {
    this.getOrder()
  },

  methods: {
    getOrder () {
      this.loading = true
      this.$api.get(`orders/${this.$route.params.order}`, {
        params: {
          with: ['products', 'products.parts'],
          withPivot: true
        }
      })
        .then((res) => {
          this.order = res.data
          this.form.billing_status = this.order.billing_status
          this.form.fulfillment_status = this.order.fulfillment_status
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    saveOrder () {
      this.loading = true
      this.$api.put(`orders/${this.$route.params.order}`, this.order)
        .then((res) => {
          this.getOrder()
          this.edit = false
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    changeStatus (type, status) {
      this.loading = true
      this.$api.put(`orders/${this.$route.params.order}/status/${type}`, { status })
        .then((res) => {
          this.getOrder()
          this.edit = false
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    openProductActions (id) {
      this.productDropdown = (this.productDropdown === id ? false : id)
    },

    showProductActions (id) {
      return this.productDropdown === id
    },

    startEdit () {
      this.orderOld = cloneDeep(this.order)
    },

    discardEdit () {
      this.order = this.orderOld
      this.orderOld = false
      this.edit = false
    }
  }
}
</script>
